import type {Subtitle} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle';
import {BACKGROUND_PADDING, createSubtitleFromObjectAndAddToTranscript} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {Item} from '@PosterWhiteboard/items/item/item.class';
import type {UpdateFromObjectOpts} from '@PosterWhiteboard/common.types';
import type {SubtitleObject} from '@PosterWhiteboard/items/transcript-item/subtitle/subtitle.types';
import type {TranscriptGeneratedFrom, TranscriptItemObject} from '@PosterWhiteboard/items/transcript-item/transcript-item.types';
import type {FabricObject, ObjectEvents} from '@postermywall/fabricjs-2';
import {IText, Canvas, FixedLayout, Group, LayoutManager, Point, Rect, Textbox} from '@postermywall/fabricjs-2';
import {getPmwMlControl, getPmwMrControl, type OnResizeParams} from '@PosterWhiteboard/poster/poster-item-controls';
import {degreesToRadians} from '@Utils/math.util';
import {TEXT_OUTLINE_STROKE_WIDTH_FACTOR, TextVerticalAlignType} from '@PosterWhiteboard/classes/text-styles.class';
import type {LetterCase} from '@Utils/string.util';
import {applyLetterCase} from '@Utils/string.util';
import type {Page} from '@PosterWhiteboard/page/page.class';
import {addItemsToGroupWithOriginalScale} from '@Utils/fabric.util';
import {SubtitleTemplateType} from '@PosterWhiteboard/items/transcript-item/subtitle/template-styles.types';
import type {OverlappingSubtitleItemResizeData} from '@Components/poster-editor/components/poster-editor-web-bottom-bar/poster-editor-web-bottom-bar.reducer';
import {getFontFamilyNameForVariations} from '@Libraries/font-library';
import {rgbToHexString} from '@Utils/color.util';
import type {DeepPartial} from '@/global';

const TRANSCRIPT_ITEM_PADDING = 32;
const NEW_TRANSCRIPT_ITEM_OFFSET_FROM_BOTTOM = 150;

export class TranscriptItem extends Item {
  declare fabricObject: Group;
  public gitype = ITEM_TYPE.TRANSCRIPT;
  public width = this.page.poster.width - TRANSCRIPT_ITEM_PADDING - TRANSCRIPT_ITEM_PADDING;
  public height = 0;

  public subtitlesHashmap: Record<string, Subtitle> = {};
  public verticalAlign: TextVerticalAlignType = TextVerticalAlignType.BOTTOM;
  public generatedFrom?: TranscriptGeneratedFrom;

  public previewImageUrl: Record<string, string> = {};

  private wasItemSelectedOnMouseDown = false;

  private smallestWidthNeeded: Record<string, number> = {};

  private readonly boundBringToFront;

  public constructor(page: Page) {
    super(page);
    this.boundBringToFront = this.bringToFront.bind(this);
  }

  public toObject(): TranscriptItemObject {
    const subtitleObjects: Record<string, SubtitleObject> = {};

    for (const [key, item] of Object.entries(this.subtitlesHashmap)) {
      subtitleObjects[key] = item.toObject();
    }

    return {
      ...super.toObject(),
      subtitlesHashmap: subtitleObjects,
      generatedFrom: this.generatedFrom,
      verticalAlign: this.verticalAlign,
    };
  }

  public getCacheKeyForPreviewImageUrl(): string {
    const subtitleObject = this.getAnySubtitle().toObject();

    return JSON.stringify({
      ...subtitleObject.sentenceTextStyles,
      ...subtitleObject.backgroundFill,
      ...subtitleObject.aura,
      backgroundBorderRadius: subtitleObject.backgroundBorderRadius,
    });
  }

  public getGeneralCacheKey(): string {
    const subtitleObject = this.getAnySubtitle().toObject();

    return JSON.stringify({
      ...subtitleObject.sentenceTextStyles,
      ...subtitleObject.activeWordTextStyles,
      ...subtitleObject.aura,
      text: subtitleObject.text,
      animationStyle: subtitleObject.animationStyle,
    });
  }

  public getPreviewImageUrl(): string {
    const cacheKey = this.getCacheKeyForPreviewImageUrl();

    if (this.previewImageUrl[cacheKey]) {
      return this.previewImageUrl[cacheKey];
    }

    const textbox = new Textbox('Aa', {
      lockMovementY: true,
      lockMovementX: true,
      lockScalingX: true,
      lockScalingY: true,
      hasControls: false,
      left: BACKGROUND_PADDING,
      top: BACKGROUND_PADDING,
    });

    const background = new Rect({
      width: textbox.width + BACKGROUND_PADDING * 2,
      height: textbox.height + BACKGROUND_PADDING * 2,
      strokeWidth: 0,
      evented: false,
      selectable: false,
    });

    const textStylesToUse = this.getAnySubtitle().sentenceTextStyles;

    textbox.set({
      ...textStylesToUse.getTextStyles(this.getAnySubtitle().backgroundFabricObject.width, this.getAnySubtitle().backgroundFabricObject.height),
      charSpacing: 10,
      shadow: this.getShadow(),
      fontFamily: textStylesToUse.fontFamily,
    });

    if (textStylesToUse.stroke) {
      textbox.set({
        strokeWidth: textStylesToUse.fontSize * textStylesToUse.strokeWidth * TEXT_OUTLINE_STROKE_WIDTH_FACTOR,
        strokeLineJoin: 'round',
        paintFirst: 'stroke',
        stroke: rgbToHexString(textStylesToUse.strokeColor, 1),
      });
    } else if (!textStylesToUse.stroke) {
      textbox.set({
        strokeWidth: 0,
        strokeLineJoin: 'miter',
        paintFirst: 'fill',
        stroke: undefined,
      });
    }

    background.set({
      rx: this.getAnySubtitle().backgroundBorderRadius,
      ry: this.getAnySubtitle().backgroundBorderRadius,
      fill: this.getAnySubtitle().backgroundFill.getFill(background.width, background.height),
    });

    background.set({
      width: textbox.width + BACKGROUND_PADDING * 2,
      height: textbox.height + BACKGROUND_PADDING * 2,
    });

    const dummyFabricGroup = new Group([background, textbox], {
      subTargetCheck: true,
      interactive: true,
      layoutManager: new LayoutManager(new FixedLayout()),
      lockMovementY: true,
      lockMovementX: true,
      hasControls: false,
      selectable: false,
    });

    dummyFabricGroup.set({
      width: background.width,
      height: background.height,
    });

    background.setPositionByOrigin(new Point(0, 0), 'center', 'center');
    textbox.setPositionByOrigin(new Point(0, 0), 'center', 'center');

    this.previewImageUrl[cacheKey] = dummyFabricGroup.toDataURL();

    return this.previewImageUrl[cacheKey];
  }

  public getFonts(withVariation: boolean): string[] {
    return [
      withVariation
        ? getFontFamilyNameForVariations(
            this.getAnySubtitle().sentenceTextStyles.fontFamily,
            this.getAnySubtitle().sentenceTextStyles.isBold,
            this.getAnySubtitle().sentenceTextStyles.isItalic
          )
        : this.getAnySubtitle().sentenceTextStyles.fontFamily,
    ];
  }

  public getSubtitleIdsInOrder(): string[] {
    return Object.entries(this.subtitlesHashmap)
      .sort(([, subtitleA], [, subtitleB]) => {
        const startTimeDifference = subtitleA.startTime - subtitleB.startTime;

        if (startTimeDifference !== 0) {
          return startTimeDifference;
        }

        return subtitleA.endTime - subtitleB.endTime;
      })
      .map(([uid]) => {
        return uid;
      });
  }

  public async onItemAddedToPage(): Promise<void> {
    this.syncAllSubtitles();
    this.page.fabricCanvas.on('object:added', this.boundBringToFront);
  }

  public onRemove(): void {
    super.onRemove();
    this.page.fabricCanvas.off('object:added', this.boundBringToFront);
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      subtitle.onRemove();
    }
  }

  public canMoveInZIndex(): boolean {
    return false;
  }

  public async updateFromObject(transcriptItemObject: DeepPartial<TranscriptItemObject>, {updateRedux = true, undoable = true}: UpdateFromObjectOpts = {}): Promise<void> {
    const {subtitlesHashmap, ...obj} = transcriptItemObject;

    this.copyVals({
      ...obj,
    });
    await this.init();

    if (subtitlesHashmap) {
      // delete items that are in this page but not in the transcriptItemObject
      for (const [uid] of Object.entries(this.subtitlesHashmap)) {
        if (subtitlesHashmap[uid] === undefined) {
          this.removeSubtitle(uid);
        }
      }

      const promises = [];

      for (const [uid, subtitleObject] of Object.entries(subtitlesHashmap)) {
        if (subtitleObject) {
          if (this.subtitlesHashmap[uid]) {
            promises.push(
              this.subtitlesHashmap[uid].updateFromObject(subtitleObject, {
                undoable: false,
                updateRedux: false,
              })
            );
          } else {
            promises.push(createSubtitleFromObjectAndAddToTranscript(this, subtitleObject));
          }
        }
      }

      await Promise.all(promises);
    }

    await this.invalidate();

    if (undoable) {
      this.page.poster.history.addPosterHistory();
    }
    if (updateRedux) {
      this.page.poster.redux.updateReduxData();
    }

    this.syncAllSubtitles();

    this.page.fabricCanvas.requestRenderAll();
  }

  public getInitialCoordinatesForTranscript(count: number): {x: number; y: number} {
    const maxHeight = this.getLargestSubtitleFabricObjectHeight();

    const adjustedY = this.page.poster.height - maxHeight - TRANSCRIPT_ITEM_PADDING - (count - 1) * NEW_TRANSCRIPT_ITEM_OFFSET_FROM_BOTTOM;
    const yForCurrentlyAddedTranscriptItem = adjustedY >= TRANSCRIPT_ITEM_PADDING ? adjustedY : TRANSCRIPT_ITEM_PADDING;

    return {
      x: TRANSCRIPT_ITEM_PADDING,
      y: yForCurrentlyAddedTranscriptItem,
    };
  }

  public async updateFabricObjectPositionAndSize(count: number): Promise<void> {
    await this.updateFromObject(this.getInitialCoordinatesForTranscript(count), {updateRedux: false, undoable: false});
  }

  public async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    this.fabricObject.set({
      width: this.width,
    });
    this.adjustLayoutAndAlignment();
    this.fabricObject.setCoords();
  }

  public getAnySubtitle(): Subtitle {
    return Object.values(this.subtitlesHashmap)[0];
  }

  public getCurrentSubtitle(): Subtitle | undefined {
    const currentTime = this.page.poster.getCurrentTime();
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      if (subtitle.startTime <= currentTime && subtitle.endTime >= currentTime) {
        return subtitle;
      }
    }
    return undefined;
  }

  public async updateAllSubtitlesToLetterCase(letterCase: LetterCase): Promise<void> {
    const newSubtitlesHashmap: Record<string, DeepPartial<SubtitleObject>> = {};
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      const wordsWithAppliedLetterCase = [];
      for (const word of subtitle.words) {
        wordsWithAppliedLetterCase.push({...word, text: applyLetterCase(word.text, letterCase)});
      }

      newSubtitlesHashmap[subtitle.subtitleUID] = {
        text: applyLetterCase(subtitle.text, letterCase),
      };
    }

    return this.updateFromObject({
      subtitlesHashmap: newSubtitlesHashmap,
    });
  }

  public async updateDimensionsForSelectedSubtitleTemplate(): Promise<void> {
    this.fabricObject.set('dirty', true);

    if (this.getAnySubtitle().animationStyle !== SubtitleTemplateType.SINGLE_WORD) {
      await this.updateFromObject({
        width: Math.max(this.getSmallestWidthNeededBySubtitleFabricTextBox(), this.width),
        height: this.getLargestSubtitleFabricObjectHeight(),
        scaleX: this.scaleX,
        scaleY: this.scaleY,
      });
    } else {
      await this.updateFromObject({
        width: Math.max(this.getSmallestWidthNeededBySubtitleFabricTextBox(), this.width),
        height: this.getLargestSubtitleFabricObjectHeight(),
        scaleX: this.scaleX,
        scaleY: this.scaleY,
      });
    }
  }

  public async updateAllSubtitles(subtitleObject: DeepPartial<SubtitleObject>, undoable = true): Promise<void> {
    const wasPlaying = this.page.poster.isPlaying();

    if (wasPlaying) {
      await this.page.poster.pause();
    }

    const newSubtitlesHashmap: Record<string, DeepPartial<SubtitleObject>> = {};
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      newSubtitlesHashmap[subtitle.subtitleUID] = subtitleObject;
    }

    await this.updateFromObject(
      {
        subtitlesHashmap: newSubtitlesHashmap,
      },
      {undoable}
    );

    await this.updateDimensionsForSelectedSubtitleTemplate();

    this.syncAllSubtitles();

    if (wasPlaying) {
      await this.page.poster.play();
    }
  }

  public onScaling(): void {}

  public adjustLayoutAndAlignment(): void {
    this.updateChildrenWidths();
    this.updateTranscriptHeight();
    this.updateSubtitlesAlignment();
  }

  public async onTextEditingExit(): Promise<void> {
    this.syncAllSubtitles();

    await this.updateFabricObject();

    this.fabricObject.set({
      subTargetCheck: false,
      interactive: false,
    });
  }

  public async onTextEditingEnter(): Promise<void> {
    if (this.page.poster.isPlaying()) {
      await this.page.poster.pause();
    }
  }

  public onTextChanged(): void {
    this.setWidth(Math.max(this.getSmallestWidthNeededBySubtitleFabricTextBox(), this.width));
  }

  public doesfabricObjBelongtoItem(fabricObj: FabricObject | Group): boolean {
    return this.fabricObject === fabricObj || this.fabricObject === fabricObj.group?.group;
  }

  public getDuration(): number {
    let lastSubtitleEndTime: number | undefined;
    let firstSubtitleStartTime: number | undefined;

    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      if (firstSubtitleStartTime === undefined || (firstSubtitleStartTime && subtitle.startTime < firstSubtitleStartTime)) {
        firstSubtitleStartTime = subtitle.startTime;
      }

      if (lastSubtitleEndTime === undefined || (lastSubtitleEndTime && subtitle.endTime > lastSubtitleEndTime)) {
        lastSubtitleEndTime = subtitle.endTime;
      }
    }

    if (lastSubtitleEndTime === undefined || firstSubtitleStartTime === undefined) {
      throw new Error(`Failed to get duration for transcript!`);
    }

    return lastSubtitleEndTime - firstSubtitleStartTime;
  }

  public isStreamingMediaItem(): boolean {
    return true;
  }

  public async seek(time: number): Promise<void> {
    const seekTime = time % this.getDuration();
    await this.page.poster.seek(seekTime);
  }

  public addSubtitle(itemToAdd: Subtitle): void {
    this.subtitlesHashmap[itemToAdd.subtitleUID] = itemToAdd;
    addItemsToGroupWithOriginalScale(this.fabricObject, [itemToAdd.fabricObject]);
  }

  protected initEvents(): void {
    super.initEvents();
    this.fabricObject.on('mousedown:before', this.onMouseDown.bind(this));
    this.fabricObject.on('mouseup', this.onMouseUp.bind(this));
  }

  protected getSmallestWidthNeededBySubtitleFabricTextBox(): number {
    if (this.smallestWidthNeeded[this.getGeneralCacheKey()]) {
      return this.smallestWidthNeeded[this.getGeneralCacheKey()];
    }

    let maxSubtitleMinWidth = 0;

    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      const minItemWidth = subtitle.getSmallestWidthNeededByFabricTextBox();
      if (minItemWidth > maxSubtitleMinWidth) {
        maxSubtitleMinWidth = minItemWidth;
      }
    }

    this.smallestWidthNeeded[this.getGeneralCacheKey()] = maxSubtitleMinWidth;

    return maxSubtitleMinWidth;
  }

  protected getFabricObjectForItem(): Promise<Group> {
    const subtitleTextAndBackground: Group[] = [];

    return new Promise((resolve) => {
      Object.values(this.subtitlesHashmap).forEach((subtitle) => {
        subtitleTextAndBackground.push(subtitle.getFabricObject());
      });

      const groupItem = new Group(subtitleTextAndBackground, {
        ...super.getCommonOptions(),
        width: this.width,
        height: this.height,
        perPixelTargetFind: true,
        layoutManager: new LayoutManager(new FixedLayout()),
      });
      resolve(groupItem);
    });
  }

  protected initCustomControls(): void {
    super.initCustomControls();
    const pmwMlControl = getPmwMlControl(this.onResizeWithLeftHandle.bind(this));
    const pmwMrControl = getPmwMrControl(this.onResizeWithRightHandle.bind(this));
    this.fabricObject.controls[pmwMlControl.key] = pmwMlControl.control;
    this.fabricObject.controls[pmwMrControl.key] = pmwMrControl.control;
  }

  protected setControlsVisibility(): void {
    super.setControlsVisibility();
    const isItemLocked = this.isLocked();
    this.fabricObject.setControlsVisibility({
      pmwMr: !isItemLocked,
      pmwMl: !isItemLocked,
    });
  }

  private onMouseDown(): void {
    if (!(this.page.fabricCanvas instanceof Canvas)) {
      throw new Error(`Page canvas neeed to be of type canvas`);
    }
    this.wasItemSelectedOnMouseDown = this.page.getSelectedItems()[0] === this;
  }

  private onMouseUp(e: ObjectEvents['mouseup']): void {
    if (!(this.page.fabricCanvas instanceof Canvas)) {
      throw new Error(`Page canvas neeed to be of type canvas`);
    }

    if (e.isClick && this.wasItemSelectedOnMouseDown && !this.fabricObject.getActiveControl()) {
      if (this.wasItemSelectedOnMouseDown) {
        this.fabricObject.set({
          subTargetCheck: true,
          interactive: true,
        });
        const currentSubtitleTextbox = this.getCurrentSubtitle()?.textFabricObject;
        if (currentSubtitleTextbox) {
          this.page.fabricCanvas.setActiveObject(currentSubtitleTextbox);
          currentSubtitleTextbox.enterEditing(e.e);
        }
      }
    }
  }

  private onResizeWithRightHandle(event: OnResizeParams): void {
    const minWidth = this.getSmallestWidthNeededBySubtitleFabricTextBox();

    const newWidth = this.fabricObject.width + event.delta / this.fabricObject.scaleX;
    if (newWidth < minWidth) {
      return;
    }

    this.setWidth(newWidth);
  }

  private onResizeWithLeftHandle(event: OnResizeParams): void {
    const minWidth = this.getSmallestWidthNeededBySubtitleFabricTextBox();

    const newWidth = this.fabricObject.width + event.delta / this.fabricObject.scaleX;
    if (newWidth < minWidth) {
      return;
    }

    this.fabricObject.set({
      left: this.fabricObject.left - event.delta * Math.cos(degreesToRadians(this.fabricObject.angle)),
      top: this.fabricObject.top - event.delta * Math.sin(degreesToRadians(this.fabricObject.angle)),
    });
    this.setWidth(newWidth);
  }

  private setWidth(width: number): void {
    this.width = width;
    this.fabricObject.set({
      width,
    });
    this.adjustLayoutAndAlignment();
  }

  private updateTranscriptHeight(): void {
    if (this.getAnySubtitle().animationStyle === SubtitleTemplateType.SINGLE_WORD) {
      this.fabricObject.set({
        height: this.height,
      });
    } else {
      const maxHeight = this.getLargestSubtitleFabricObjectHeight();
      if (maxHeight)
        this.fabricObject.set({
          height: maxHeight,
        });
    }
  }

  private getLargestSubtitleFabricObjectHeight(): number {
    if (this.getAnySubtitle().animationStyle === SubtitleTemplateType.SINGLE_WORD) {
      let maxHeight = -1;

      for (const subtitle of Object.values(this.subtitlesHashmap)) {
        for (const word of subtitle.words) {
          const tempTextFabricObject = new IText(word.text, {
            shadow: subtitle.aura.getItemAura(subtitle.getScaleForShadow()),
            ...subtitle.sentenceTextStyles.getTextStyles(subtitle.textFabricObject.width, subtitle.textFabricObject.height),
          });

          if (tempTextFabricObject.height > maxHeight) {
            maxHeight = tempTextFabricObject.height;
          }
        }
      }

      return maxHeight + BACKGROUND_PADDING * 2;
    }

    let maxObjectHeight = 0;
    for (const object of this.fabricObject.getObjects()) {
      if (object.height > maxObjectHeight) {
        maxObjectHeight = object.height;
      }
    }

    return maxObjectHeight;
  }

  private updateChildrenWidths(): void {
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      subtitle.setMaxWidth(this.width);
    }
  }

  private removeSubtitle(uid: string): void {
    if (this.hasItem(uid)) {
      this.subtitlesHashmap[uid].onRemove();
      this.fabricObject.remove(this.subtitlesHashmap[uid].fabricObject);
      delete this.subtitlesHashmap[uid];
    }
  }

  private hasItem(uid: string): boolean {
    return this.subtitlesHashmap[uid] !== undefined;
  }

  private updateSubtitlesAlignment(): void {
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      if (this.verticalAlign === TextVerticalAlignType.TOP) {
        subtitle.fabricObject.setPositionByOrigin(new Point(0, -this.fabricObject.height / 2), 'center', 'top');
      } else if (this.verticalAlign === TextVerticalAlignType.CENTER) {
        subtitle.fabricObject.setPositionByOrigin(new Point(0, 0), 'center', 'center');
      } else if (this.verticalAlign === TextVerticalAlignType.BOTTOM) {
        subtitle.fabricObject.setPositionByOrigin(new Point(0, this.fabricObject.height / 2 - subtitle.fabricObject.height), 'center', 'top');
      }
    }
  }

  private bringToFront(): void {
    this.page.fabricCanvas.bringObjectToFront(this.fabricObject);
  }

  private syncAllSubtitles(): void {
    for (const [, subtitle] of Object.entries(this.subtitlesHashmap)) {
      subtitle.syncSubtitle();
    }
  }

  public areSubtitlesOverlapping(): boolean {
    const intervals: [number, number][] = [];
    for (const [, item] of Object.entries(this.subtitlesHashmap)) {
      intervals.push([item.startTime, item.endTime]);
    }

    intervals.sort((a, b) => {
      return a[0] - b[0];
    });

    for (let i = 0; i < intervals.length - 1; i++) {
      const currentEnd = intervals[i][1];
      const nextStart = intervals[i + 1][0];

      if (nextStart <= currentEnd) {
        return true;
      }
    }

    return false;
  }

  public deleteItemWithId(subtitleId: string): void {
    this.removeSubtitle(subtitleId);
  }

  public async updateOverlappingSubtitles(data: OverlappingSubtitleItemResizeData): Promise<void> {
    const leeway = data.direction === 'end' ? 0.01 : -0.01;
    for (let i = 0; i < data.ids.length; i++) {
      await this.subtitlesHashmap[data.ids[i]].updateFromObject(
        {
          startTime: this.subtitlesHashmap[data.ids[i]].startTime + data.overlap + leeway,
          endTime: this.subtitlesHashmap[data.ids[i]].endTime + data.overlap + leeway,
        },
        {undoable: false}
      );
    }
  }

  public async trimSubtitlesExceedingDuration(duration: number): Promise<void> {
    for (const [, item] of Object.entries(this.subtitlesHashmap)) {
      if (item.startTime > duration) {
        this.removeSubtitle(item.subtitleUID);
      } else if (Math.abs(item.startTime - duration) < 1) {
        this.removeSubtitle(item.subtitleUID);
      } else if (item.endTime > duration) {
        await item.updateFromObject(
          {
            endTime: duration,
            hasUserEdited: true,
          },
          {undoable: false}
        );
      }
    }
  }

  public getEndTimeOfLastItem(): number {
    const listOfEndTimes = [];
    for (const [, item] of Object.entries(this.subtitlesHashmap)) {
      listOfEndTimes.push(item.endTime);
    }

    return Math.max(...listOfEndTimes);
  }
}

export const getTranscriptItemFromSubtitleId = (id: string): TranscriptItem | null => {
  const items = window.posterEditor?.whiteboard?.getCurrentPage().items.itemsHashMap;
  if (!items) {
    return null;
  }

  let transcripItem: TranscriptItem | null = null;

  for (const [, item] of Object.entries(items)) {
    if (item.isTranscript()) {
      if (id in item.subtitlesHashmap) {
        transcripItem = item;
        break;
      }
    }
  }
  return transcripItem;
};
