import {TIMELINE_MAX_WIDTH_PER_SECOND, TIMELINE_MIN_WIDTH_PER_SECOND} from '@Components/poster-editor/components/poster-editor-web-bottom-bar/poster-editor-web-bottom-bar.types';
import type {ItemDefinition, Range, RowDefinition} from '@Libraries/timeline/types';
import type {CSSProperties} from 'react';
import {updateTimelineWidthPerSecond} from '@Components/poster-editor/components/poster-editor-web-bottom-bar/poster-editor-web-bottom-bar.reducer';
import {GA4EventName, GA4EventParamName, trackPosterBuilderGA4Events} from '@Libraries/ga-events';
import {isEditorMobileVariant} from '@Components/poster-editor/library/poster-editor-library';
import type {AudioItemObject} from '@PosterWhiteboard/classes/audio-clips/audio-item.class';
import {getTranscriptItemFromSubtitleId} from '@PosterWhiteboard/items/transcript-item/transcript-item';
import {isOverlapping} from '@Components/poster-editor/components/poster-editor-web-bottom-bar/components/timeline-subtitle-item/timeline-subtitle-item.helper';
import {POSTER_MAX_DURATION} from '@PosterWhiteboard/poster/poster.types';
import type {DeepPartial} from '@/global';

export const getScaleFromValue = (widthPerSec: number, fitWidth: number): number => {
  return calculateScalePercentage(widthPerSec, TIMELINE_MIN_WIDTH_PER_SECOND, fitWidth, TIMELINE_MAX_WIDTH_PER_SECOND);
};

function roundToNearest5(value: number): number {
  return Math.round(value / 5) * 5;
}

function calculateScalePercentage(widthPerSecond: number, minWidth: number, fitWidth: number, maxWidth: number): number {
  const contentWidth = Math.max(minWidth, Math.min(widthPerSecond, maxWidth));

  const minPercentage = 20;
  const fitPercentage = 80;
  const maxPercentage = 400;

  const range1 = fitWidth - minWidth;
  const range2 = maxWidth - fitWidth;

  let scalePercentage: number;

  if (contentWidth <= fitWidth) {
    const scaleFactor1 = (contentWidth - minWidth) / range1;
    scalePercentage = minPercentage + scaleFactor1 * (fitPercentage - minPercentage);
  } else {
    const normalizedFactor = (contentWidth - fitWidth) / range2;
    const exponentialFactor = 2 ** normalizedFactor - 1; // This grows rapidly as normalizedFactor increases
    scalePercentage = fitPercentage + exponentialFactor * (maxPercentage - fitPercentage);
  }

  scalePercentage = roundToNearest5(scalePercentage);

  return Math.min(maxPercentage, scalePercentage);
}

export const TIMELINE_DESIGN_ITEM_ID = 'design-seek';

export enum TimelineItemTypeId {
  Design = 'design-timeline',
  Audio = 'audio-timeline',
  Transcript = 'transcript-timeline',
}

export const generateTimelineItems = (): ItemDefinition[] => {
  return [...generateDesignSeekItem(), ...generateAudioItems(), ...generateSubtitleItems()];
};

export const generateDesignSeekItem = (): ItemDefinition[] => {
  const items: ItemDefinition[] = [];
  const posterDuration = window.posterEditor?.whiteboard?.getCurrentPage().getDuration();

  if (!posterDuration) {
    return items;
  }

  const item: ItemDefinition = {
    id: TIMELINE_DESIGN_ITEM_ID,
    rowId: TimelineItemTypeId.Design,
    span: {
      start: 0,
      end: posterDuration,
    },
  };

  return [item];
};

export const generateSubtitleItems = (): ItemDefinition[] => {
  const items: ItemDefinition[] = [];
  const transcriptItems = window.posterEditor?.whiteboard?.getCurrentPage().items.getAllTranscriptItems();

  if (!transcriptItems) {
    return [];
  }

  if (Object.keys(transcriptItems).length === 0) {
    return [];
  }

  for (const [, item] of Object.entries(transcriptItems)) {
    for (const [, subtitleItem] of Object.entries(item.subtitlesHashmap)) {
      items.push({
        id: subtitleItem.subtitleUID,
        rowId: `${TimelineItemTypeId.Transcript}_${item.uid}`,
        disabled: false,
        span: {
          start: subtitleItem.startTime,
          end: subtitleItem.endTime,
        },
        data: {
          transcriptId: item.uid,
        },
      });
    }

    items.push({
      id: `subtitle-display`,
      rowId: `${TimelineItemTypeId.Transcript}_${item.uid}`,
      disabled: false,
      span: {
        start: 0,
        end: 0,
      },
      data: {
        transcriptId: item.uid,
        isDisplayOnly: true,
      },
    });
  }

  return items;
};

export const generateAudioItems = (): ItemDefinition[] => {
  const items: ItemDefinition[] = [];
  const audioHashmap = window.posterEditor?.whiteboard?.audioClips.audioItemsHashMap;
  if (!audioHashmap) {
    return [];
  }
  const keys = Object.keys(audioHashmap);

  for (const eachKey of keys) {
    if (audioHashmap) {
      const item: ItemDefinition = {
        id: audioHashmap[eachKey].uid,
        rowId: TimelineItemTypeId.Audio,
        disabled: false,
        span: {
          start: audioHashmap[eachKey].onPosterStartTime,
          end: audioHashmap[eachKey].onPosterStartTime + audioHashmap[eachKey].audioPlayer.getPlaybackDuration(),
        },
      };
      items.push(item);
    }
  }

  return items;
};

export const getTimelineItemTransformProperty = (itemStyle: CSSProperties): string => {
  if (!itemStyle.transform) {
    return '';
  }

  const result = itemStyle.transform.substring(itemStyle.transform.indexOf('(') + 1, itemStyle.transform.lastIndexOf(')'));
  const splittedResult = result.split(',');
  return `translate3d(${splittedResult[0]} , 0px , ${splittedResult[2]})`;
};

export const generateTranscriptItemRows = (): RowDefinition[] => {
  const transcriptItems = window.posterEditor?.whiteboard?.getCurrentPage().items.getTranscriptItemIds();

  if (!transcriptItems || (transcriptItems && transcriptItems.length === 0)) {
    return [];
  }

  const rows: RowDefinition[] = [];
  for (let i = 0; i < transcriptItems.length; i++) {
    rows.push({
      id: `${TimelineItemTypeId.Transcript}_${transcriptItems[i]}`,
      disabled: false,
    });
  }

  return rows;
};

export const zoomTimelineIn = (currentWidthPerSecond: number): void => {
  const scale = currentWidthPerSecond < 40 ? 1.4 : 1.1;
  const value = scale * currentWidthPerSecond;
  window.PMW.redux.store.dispatch(updateTimelineWidthPerSecond(value));
};

export const zoomTimelineOut = (currentWidthPerSecond: number): void => {
  const scale = currentWidthPerSecond < 56 ? 1.4 : 1.1;
  const value = currentWidthPerSecond / scale;

  window.PMW.redux.store.dispatch(updateTimelineWidthPerSecond(value));
};

export const fireGAEventForTimelineOpen = (): void => {
  const isMobile = isEditorMobileVariant();
  trackPosterBuilderGA4Events(GA4EventName.TIMELINE_OPEN, {
    [GA4EventParamName.TYPE]: isMobile ? 'mobile' : 'web',
  });
};

export const fireGaEventForTimelineOpenFromBottomBar = (): void => {
  trackPosterBuilderGA4Events(GA4EventName.TIMELINE_OPEN_BOTTOM_BAR);
};

export const fireGaEventForTimelineOpenFromAudioIcon = (): void => {
  trackPosterBuilderGA4Events(GA4EventName.TIMELINE_OPEN_AUDIO_ICON);
};

export const isAValidAudioItem = (id: string): boolean => {
  const audioItemHashmap = window.posterEditor?.whiteboard?.audioClips.audioItemsHashMap;

  return !!audioItemHashmap?.[id];
};

export const isAValidTranscriptSubtitleItem = (id: string): boolean => {
  const itemsHashmap = window.posterEditor?.whiteboard?.getCurrentPage().items.itemsHashMap;

  if (!itemsHashmap?.[id]) {
    return false;
  }

  if (!itemsHashmap[id]) {
    return false;
  }

  return itemsHashmap[id].isTranscript();
};

export const onTranscriptItemResizeEnd = async (updatedRange: Range, subtitleId: string): Promise<void> => {
  const posterDuration = window.posterEditor?.whiteboard?.getCurrentPage().getDuration();
  if (!posterDuration) {
    return;
  }

  const transcriptItem = getTranscriptItemFromSubtitleId(subtitleId);
  if (!transcriptItem) {
    return;
  }

  const subtitle = transcriptItem.subtitlesHashmap[subtitleId];
  let processedUpdatedRange = updatedRange;

  if (updatedRange.start < 0) {
    processedUpdatedRange = {
      start: 0,
      end: updatedRange.end - updatedRange.start,
    };
  }

  if (updatedRange.start > posterDuration) {
    processedUpdatedRange = {
      start: posterDuration - 1,
      end: posterDuration,
    };
  }

  if (updatedRange.end > posterDuration && updatedRange.start < posterDuration) {
    processedUpdatedRange = {
      start: updatedRange.start,
      end: posterDuration,
    };
  }

  processedUpdatedRange = {
    start: Math.min(posterDuration - 1, processedUpdatedRange.start),
    end: processedUpdatedRange.end,
  };

  const sortedIds: [string, number, number][] = [];
  for (const [key, item] of Object.entries(transcriptItem.subtitlesHashmap)) {
    if (key !== subtitleId) {
      sortedIds.push([key, item.startTime, item.endTime]);
    }
  }
  sortedIds.sort((a, b) => {
    return a[1] - b[1];
  });

  let overlap = false;
  const overlapIds: string[] = [];
  for (const eachItem of sortedIds) {
    if (isOverlapping(processedUpdatedRange, eachItem[1], eachItem[2])) {
      overlapIds.push(eachItem[0]);
      overlap = true;
    }
  }

  if (!overlap) {
    await subtitle.updateFromObject({
      startTime: processedUpdatedRange.start,
      endTime: processedUpdatedRange.end,
      hasUserEdited: true,
    });
    return;
  }

  if (overlapIds.length < 1) {
    return;
  }

  const firstOverlappingItem = transcriptItem.subtitlesHashmap[overlapIds[0]];
  const overlapPercentage = findOverlappingPercentageWithRespectToStart(firstOverlappingItem.startTime, firstOverlappingItem.endTime, updatedRange);
  if (overlapPercentage < 50) {
    const durationForSubtitle = subtitle.endTime - subtitle.startTime;
    const subtitleNewEndTime = firstOverlappingItem.startTime - 0.01;
    const subtitleNewStartTime = subtitleNewEndTime - durationForSubtitle;
    if (subtitleNewStartTime <= 0 || subtitleNewEndTime < 0) {
      return;
    }

    // check if item behind then move all subtitles forward
    const itemBehindIndex =
      sortedIds
        .map((item) => {
          return item[0];
        })
        .indexOf(overlapIds[0]) - 1;

    if (
      itemBehindIndex >= 0 &&
      transcriptItem.subtitlesHashmap[sortedIds[itemBehindIndex][0]] &&
      isOverlapping(
        {
          start: subtitleNewStartTime,
          end: subtitleNewEndTime,
        },
        transcriptItem.subtitlesHashmap[sortedIds[itemBehindIndex][0]].startTime,
        transcriptItem.subtitlesHashmap[sortedIds[itemBehindIndex][0]].endTime
      )
    ) {
      const itemBehindId = sortedIds[itemBehindIndex][0];

      const subtitleIdsThatNeedEditing = getIdsInFrontOfTarget(
        sortedIds.map((item) => {
          return item[0];
        }),
        itemBehindId
      );

      const durationOfItemBeingResized = subtitle.endTime - subtitle.startTime;

      await subtitle.updateFromObject(
        {
          startTime: firstOverlappingItem.startTime,
          endTime: durationOfItemBeingResized + firstOverlappingItem.startTime,
          hasUserEdited: true,
        },
        {
          undoable: false,
        }
      );

      for (let i = 0; i < subtitleIdsThatNeedEditing.length; i++) {
        void transcriptItem.subtitlesHashmap[subtitleIdsThatNeedEditing[i]].updateFromObject(
          {
            startTime: transcriptItem.subtitlesHashmap[subtitleIdsThatNeedEditing[i]].startTime + durationOfItemBeingResized,
            endTime: transcriptItem.subtitlesHashmap[subtitleIdsThatNeedEditing[i]].endTime + durationOfItemBeingResized,
            hasUserEdited: true,
          },
          {
            undoable: true,
          }
        );
      }
      // MAKE THIS UNDOABLE
      return;
    }

    await subtitle.updateFromObject({
      startTime: subtitleNewStartTime,
      endTime: subtitleNewEndTime,
      hasUserEdited: true,
    });
    return;
  }

  const subtitleIdsThatNeedEditing = getIdsInFrontOfTarget(
    sortedIds.map((item) => {
      return item[0];
    }),
    overlapIds[0]
  );

  const subtitleDuration = subtitle.endTime - subtitle.startTime;

  if (!subtitleIdsThatNeedEditing || subtitleIdsThatNeedEditing.length === 0) {
    const newEndTime = Math.min(firstOverlappingItem.endTime + 0.01 + subtitleDuration, POSTER_MAX_DURATION);
    await subtitle.updateFromObject({
      startTime: firstOverlappingItem.endTime + 0.01,
      endTime: newEndTime,
      hasUserEdited: true,
    });

    await window.posterEditor?.whiteboard?.getCurrentPage().updateFromObject(
      {
        duration: newEndTime,
      },
      {
        undoable: false,
      }
    );

    return;
  }

  const isOverlappingWithNextItem = isOverlapping(
    {
      start: firstOverlappingItem.endTime + 0.01,
      end: firstOverlappingItem.endTime + 0.01 + subtitleDuration,
    },
    transcriptItem.subtitlesHashmap[subtitleIdsThatNeedEditing[0]].startTime,
    transcriptItem.subtitlesHashmap[subtitleIdsThatNeedEditing[0]].endTime
  );

  const overlappingValue = transcriptItem.subtitlesHashmap[subtitleIdsThatNeedEditing[0]].startTime - firstOverlappingItem.endTime + 0.01 + subtitleDuration;
  await subtitle.updateFromObject({
    startTime: firstOverlappingItem.endTime + 0.01,
    endTime: firstOverlappingItem.endTime + 0.01 + subtitleDuration,
    hasUserEdited: true,
  });

  if (isOverlappingWithNextItem) {
    let lastEndTime: number | null = null;
    for (let i = 0; i < subtitleIdsThatNeedEditing.length; i++) {
      const currentItem = transcriptItem.subtitlesHashmap[subtitleIdsThatNeedEditing[i]];
      lastEndTime = currentItem.endTime + overlappingValue;
      void currentItem.updateFromObject(
        {
          startTime: currentItem.startTime + overlappingValue,
          endTime: Math.min(currentItem.endTime + overlappingValue, POSTER_MAX_DURATION),
          hasUserEdited: true,
        },
        {undoable: false}
      );
    }

    void window.posterEditor?.whiteboard?.getCurrentPage().updateFromObject(
      {
        duration: Math.min(lastEndTime ?? posterDuration, POSTER_MAX_DURATION),
      },
      {
        undoable: false,
      }
    );
  }
};

export function getIdsInFrontOfTarget(ids: string[], targetId: string): string[] {
  const targetIndex = ids.indexOf(targetId);
  if (targetIndex === -1 || targetIndex === ids.length - 1) {
    return [];
  }

  return ids.slice(targetIndex + 1);
}

export const findOverlappingPercentageWithRespectToStart = (startTime: number, endTime: number, range: Range): number => {
  return ((range.start - startTime) * 100) / (endTime - startTime);
};

const updateAudioData = async (activeItemId: string, obj: DeepPartial<AudioItemObject>, undoable = true): Promise<void> => {
  await window.posterEditor?.whiteboard?.audioClips.audioItemsHashMap[activeItemId].updateFromObject(obj, {
    undoable,
  });
};

export const onAudioItemResizeEnd = async (updatedRange: Range, activeItemId: string): Promise<void> => {
  const audioData = window.posterEditor?.whiteboard?.audioClips;
  let newOnPosterStartTime = updatedRange.start;
  const posterDuration = window.posterEditor?.whiteboard?.getDuration();

  if (!audioData || !posterDuration) {
    return;
  }

  const audioDuration =
    (audioData.audioItemsHashMap[activeItemId].audioPlayer.trim.endTime - audioData.audioItemsHashMap[activeItemId].audioPlayer.trim.startTime) /
    audioData.audioItemsHashMap[activeItemId].audioPlayer.speed;

  if (newOnPosterStartTime > posterDuration) {
    newOnPosterStartTime = posterDuration - audioDuration;
    await updateAudioData(activeItemId, {
      onPosterStartTime: Number(newOnPosterStartTime.toFixed(2)),
    });
    return;
  }

  if (newOnPosterStartTime < 0) {
    newOnPosterStartTime = 0;
  }

  let objectForUpdate: DeepPartial<AudioItemObject> = {
    onPosterStartTime: newOnPosterStartTime,
  };

  if (audioDuration + newOnPosterStartTime > posterDuration) {
    const newEndTime =
      (posterDuration - newOnPosterStartTime + audioData.audioItemsHashMap[activeItemId].audioPlayer.trim.startTime) * audioData.audioItemsHashMap[activeItemId].audioPlayer.speed;
    const newDuration = (newEndTime - audioData.audioItemsHashMap[activeItemId].audioPlayer.trim.startTime) / audioData.audioItemsHashMap[activeItemId].audioPlayer.speed;

    if (newDuration < 1) {
      objectForUpdate = {
        onPosterStartTime: Number((posterDuration - 1 / audioData.audioItemsHashMap[activeItemId].audioPlayer.speed).toFixed(2)),
        audioPlayer: {
          trim: {
            isTrimmed: true,
            endTime: Number((audioData.audioItemsHashMap[activeItemId].audioPlayer.trim.startTime + 1).toFixed(2)),
          },
        },
      };
    } else {
      objectForUpdate = {
        onPosterStartTime: Number(newOnPosterStartTime.toFixed(2)),
        audioPlayer: {
          trim: {
            isTrimmed: true,
            endTime: Number(newEndTime.toFixed(2)),
          },
        },
      };
    }
  }

  await updateAudioData(activeItemId, objectForUpdate);
};
